.terms__page{
    width: 100%;
    max-width: 410px;
    box-sizing: border-box;
    padding: 15px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.terms__page_title{
    position: relative;
    text-align: center;
    width: fit-content;
    margin: 25px auto 0;
    font-size: 1.3em;
}
.terms__page_text{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
}
.terms__page_button{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    border: none;
    border-radius: 100px;
    width: 80%;
    max-width: 240px;
    line-height: 60px;
    margin: 32px auto 0;
    background: var(--main-button-red);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    align-self: center;
    transition: all 0.4s;
}
/* .terms__page_button:active,
.terms__page_button:focus{
    background: var(--main-button-red-active);
} */
.terms__page_button:hover{
    background: var(--main-button-red-hover);
}

.terms__page_title:before{
    content: '';
    display: block;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
    background-size: 24px;
    background-image: url(./img/page.png);
}
p{
    text-align: justify;
    text-align-last: left;
}


@media screen and (max-height: 500px) and (orientation: landscape) and (min-width: 500px) {

    .terms__page_title{
        margin-top: 1%;
      } 
    .terms__page{
        padding-left: 0;
        padding-right: 0;
        max-width: 430px;
    }
    .terms__page_text{
        font-size: 14px;
        line-height: 16px;
    }
    .terms__page_button{
        font-size: 20px;
        line-height: 51px;
        margin-top: 12px;
    }
    .terms__page_title{
        font-size: 20px;
        line-height: 24px;
    }
}
@media screen and (max-height: 350px) and (orientation: landscape) and (min-width: 500px){
    .terms__page{
        padding-top: 4px;
    }
    .terms__page_text p{
        margin-top: 8px;
    }
    .terms__page_title{
        font-size: 1.1em;
    }
  }

@media screen and (max-width: 550px){
    .terms__page_title{
        font-size: 1.3em;
        width: 70%;
    }
}

@media screen and (max-width: 376px){
    .terms__page_title{
        font-size: 1.2em;
        width: 65%;
    }
}
@media screen and (max-width: 360px){
    .terms__page_title{
        font-size: 1.1em;
        width: 70%;
    }
}
